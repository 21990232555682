import { Mesh } from '@zeainc/zea-engine'

/**
 * A class for generating a Triangle geometry.
 *
 * @extends {Mesh}
 */
class Triangle extends Mesh {
  /**
   * Create a Triangle.
   */
  constructor() {
    super()

    this.setFaceCounts([1])
    this.setFaceVertexIndices(0, [0, 2, 1])
    this.setNumVertices(3)
  }
}

export { Triangle }
