﻿import { BaseGeomItem, Material, FileImage, resourceLoader, BinReader, SystemDesc, Version } from '@zeainc/zea-engine'

class FEAAsset extends BaseGeomItem {
  constructor(name) {
    super(name)

    this.elementAttributesData = {}
    this.nodeAttributesData = {}
    this.isLoaded = false
  }

  loadSim(url) {
    return new Promise((resolve, reject) => {
      const folder = url.lastIndexOf('/') > -1 ? url.substring(0, url.lastIndexOf('/')) + '/' : ''
      const filename = url.lastIndexOf('/') > -1 ? url.substring(url.lastIndexOf('/') + 1) : ''
      const stem = filename.substring(0, filename.lastIndexOf('.'))

      this.setName(filename)
      this.folder = folder

      resourceLoader.loadFile('archive', url).then((entries) => {
        let reader = new BinReader(entries.data.buffer, 0, SystemDesc.isMobileDevice)
        this.readBinary(reader, {})

        const data = {
          elementAttributes: this.elementAttributes,
          nodeAttributes: this.nodeAttributes,
        }
        this.emit('loaded', data)
        resolve(data)
      })
    })
  }

  setActiveNodeAttribute(name, range, type) {
    return new Promise((resolve, reject) => {
      if (!this.nodeAttributes.includes(name)) {
        console.warn('Node Attribute not available:' + name)
        return
      }
      if (!this.nodeAttributesData[name]) {
        const url = this.folder + name + '.attr'
        resourceLoader.loadFile('archive', url).then((entries) => {
          this.nodeAttributesData[name] = new Float32Array(entries.data.buffer)
          this.emit('activeNodeAttributeChanged', { name, data: this.nodeAttributesData[name], range, type })
          resolve()
        })
      } else {
        this.emit('activeNodeAttributeChanged', { name, data: this.nodeAttributesData[name], range, type })
        resolve()
      }
    })
  }

  setActiveElementAttribute(name) {
    return new Promise((resolve, reject) => {
      if (!this.elementAttributes.includes(name)) {
        console.warn('Element Attribute not available:' + name)
        return
      }
      if (!this.elementAttributesData[name]) {
        const url = this.folder + name + '.attr'
        resourceLoader.loadFile('archive', url).then((entries) => {
          console.log('loadNodeAttribute')
          this.elementAttributesData[name] = new Float32Array(entries.data.buffer)
          this.emit('activeElementAttributeChanged', { name, data: this.elementAttributesData[name] })
          resolve()
        })
      } else {
        this.emit('activeElementAttributeChanged', { name, data: this.elementAttributesData[name] })
        resolve()
      }
    })
  }

  readBinary(reader, context) {
    this.version = new Version(reader.loadStr())
    context.version = this.version
    this.numNodes = reader.loadUInt32()
    this.elementNodeCounts = reader.loadUInt32Array()
    this.elementNodeIndices = reader.loadFloat32Array()
    this.elementAttributes = reader.loadStrArray()
    this.nodeAttributes = reader.loadStrArray()

    const nodePositions = reader.loadFloat32Array(this.numNodes * 3)

    this.data = {
      numNodes: this.numNodes,
      elementNodeCounts: this.elementNodeCounts,
      elementNodeIndices: this.elementNodeIndices,
      nodePositions,
    }

    this.geomBBox = reader.loadBox3()

    this.isLoaded = true
  }

  /**
   * The _cleanBoundingBox method.
   * @param {Box3} bbox - The bounding box value.
   * @return {Box3} - The return value.
   * @private
   */
  _cleanBoundingBox(bbox) {
    bbox = super._cleanBoundingBox(bbox)
    if (this.isLoaded) {
      const xfo = this.getParameter('GlobalXfo').getValue()
      bbox.addBox3(this.geomBBox, xfo.toMat4())
    }
    return bbox
  }
}

export { FEAAsset }
