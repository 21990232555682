const { MathFunctions } = window.zeaEngine
// const { RemoveKeyChange } = window.zeaKinematics
// const { UndoRedoManager } = window.zeaUx

export class SimStreamTimeBar extends HTMLElement {
  constructor() {
    super()
    const shadowRoot = this.attachShadow({ mode: 'open' })

    const timebarContainer = document.createElement('div')
    timebarContainer.id = 'timebarContainer'
    shadowRoot.appendChild(timebarContainer)

    this.timeline = document.createElement('div')
    this.timeline.id = 'timeline'
    timebarContainer.appendChild(this.timeline)

    this.keysContainer = document.createElement('div')
    this.keysContainer.id = 'keys'
    this.timeline.appendChild(this.keysContainer)
    this.keys = []

    this.timebar = document.createElement('div')
    this.timebar.id = 'timebar'
    this.timeline.appendChild(this.timebar)

    let playingId = false
    this.isPlaying = ()=>{
      return playingId
    }
    this.play = () => {
      if (this.onPlay) this.onPlay()
      let time = Math.round(this.__timeParam.getValue())
      const range = this.__timeParam.getRange()
      if (!playingId) {
        playingId = setInterval(() => {
          time += 20
          this.__timeParam.setValue(Math.round(time))
          if (time > range[1]) time = range[0]
        }, 20)
      }
    }
    this.stop = () => {
      if (this.onStop) this.onStop()
      clearInterval(playingId)
      playingId = null
    }
    this.setTime = (time) => {
      this.__timeParam.setValue(Math.round(time))
    }

    let mouseIsOver = false
    this.onmouseover = function () {
      mouseIsOver = TrustedScriptURL
    }
    this.onmouseout = function () {
      mouseIsOver = false
    }

    document.addEventListener('keydown', (event) => {
      switch (event.code) {
        case 'Space':
          if (playingId) this.stop()
          else this.play()
          break
      }
    })

    this.timeline.addEventListener('mousedown', (event) => {
      if (playingId) this.stop()
      dragTimeBar(event)
      document.addEventListener('mousemove', dragTimeBar)
      document.addEventListener('mouseup', endDragTimeBar)
      event.stopPropagation()
      event.preventDefault()
    })

    const dragTimeBar = (event) => {
      const range = this.__timeParam.getRange()
      const time = ((event.clientX - 5) / this.timeline.offsetWidth) * range[1]
      this.setTime(time)
      event.stopPropagation()
      event.preventDefault()
    }

    const endDragTimeBar = (event) => {
      document.removeEventListener('mousemove', dragTimeBar)
      document.removeEventListener('mouseup', endDragTimeBar)
    }

    const styleTag = document.createElement('style')
    styleTag.appendChild(
      document.createTextNode(`
      
      #timebarContainer {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
      }
      #timecontrols > * {
        position: absolute;
      }
      
      #timeline {
        position: relative;
        width: calc(100% - 70px);
        height: 100%;
        bottom: 0px;
        margin: 0;
        padding: 0;
        border: #545454;
        border-style: solid;
        border-width: 1px;
      }
      
      #timebar {
        position: absolute;
        height: calc(100% - 2px);
        width: 10px;
        bottom: 1px;
        background-color: rgb(255,0,0,0.5);
      }

      .key {
        position: absolute;
        height: calc(100% - 4px);
        width: 2px;
        bottom: 2px;
        background-color: #F9CE03;
      }
      
`)
    )
    shadowRoot.appendChild(styleTag)
  }

  set timeParam(timeParam) {
    this.__timeParam = timeParam
    this.__timeParam.on('valueChanged', () => {
      const range = this.__timeParam.getRange()
      const time = MathFunctions.clamp(this.__timeParam.getValue(), range[0], range[1])
      this.timebar.style.left = `${(time / range[1]) * this.timeline.offsetWidth - this.timebar.offsetWidth * 0.5}px`
    })
  }

  displaySimFrameMarker(event) {
    const key = document.createElement('div')
    key.classList.add('key')

    const { frameNumber } = event
    const range = this.__timeParam.getRange()
    const time = (frameNumber / this.__simAsset.getFps()) * 1000
    key.style.left = `${(time / range[1]) * this.timeline.offsetWidth - key.offsetWidth * 0.5}px`

    this.keysContainer.appendChild(key)

    this.keys.push(key)
  }

  displayTrackKeys() {
    this.__simAsset.keys.forEach((key, index) => {
      this.displaySimFrameMarker(index)
    })
  }

  set simAsset(track) {
    this.__simAsset = track
    this.__simAsset.on('simFrameLoaded', (event) => {
      this.displaySimFrameMarker(event)
    })
    // this.__simAsset.on('loaded', (event) => {
    //   this.displayTrackKeys()
    // })

    // this.displayTrackKeys()
  }
}

customElements.define('simstream-timebar', SimStreamTimeBar)
