
let worker
function freeMem(data) {
  if (!worker) {
    const blob = new Blob([``], { type: "text/javascript" })
    worker = new Worker(window.URL.createObjectURL(blob));
  }
  worker.postMessage("goodbye", data); // Start the worker.
//   worker.terminate()
}

export {freeMem}    