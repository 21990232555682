import { Beam } from './Beam.js'
import { Tetrahedron } from './Tetrahedron.js'
import { Triangle } from './Triangle.js'
import { FEAShader } from './FEAShader.js'
import { FEAAsset } from './FEAAsset.js'

import { Color, MathFunctions, FileImage, GLTexture2D, GLMesh, GLLines, GLPass, PassType } from '@zeainc/zea-engine'

const pixelsPerItem = 6 // The number of pixels per draw item.

/** Class representing a GL treeitems pass.
 * @extends GLPass
 * @private
 */
class GLFEAPass extends GLPass {
  /**
   * Create a GL treeitems pass.
   */
  constructor() {
    super()
    this.elementGeoms = []
    this.colorMaps = {}
    this.feaAssets = []
    this.bands = 10
    this.__updateRequested = false
    this.enableTransparency = false
    this.renderVolumetrics = false
    this.displaySolution = false
  }

  /**
   * The getPassType method.
   * @return {number} - The pass type value.
   */
  getPassType() {
    return PassType.OPAQUE
  }

  /**
   * The init method.
   * @param {GLBaseRenderer} renderer - The renderer value.
   * @param {number} passIndex - The index of the pass in the GLBAseRenderer
   */
  init(renderer, passIndex) {
    super.init(renderer, passIndex)

    const gl = this.__renderer.gl
    this.elementGeoms[0] = new GLLines(gl, new Beam())
    this.elementGeoms[1] = new GLMesh(gl, new Triangle())
    this.elementGeoms[2] = new GLMesh(gl, new Tetrahedron())
    this.glshader = new FEAShader(gl)
  }

  /**
   * Loads a gradient image to be used to visualize a type of FEA attribute,
   * @param {string} url - The url of the image to load.
   * @param {string} type - The type of value this gradient is for displaying. e.g. 'TEMP', 'PRESSURE' or 'STRESS'
   */
  loadGradient(url, type) {
    const gl = this.__renderer.gl
    const gradient = new FileImage('gradient', url)
    this.colorMaps[type] = new GLTexture2D(gl, gradient)
  }

  /**
   * The itemAddedToScene method is called on each pass when a new item
   * is added to the scene, and the renderer must decide how to render it.
   * It allows Passes to select geometries to handle the drawing of.
   * @param {TreeItem} treeItem - The treeItem value.
   * @param {object} rargs - Extra return values are passed back in this object.
   * The object contains a parameter 'continueInSubTree', which can be set to false,
   * so the subtree of this node will not be traversed after this node is handled.
   * @return {Boolean} - The return value.
   */
  itemAddedToScene(treeItem, rargs) {
    if (treeItem instanceof FEAAsset) {
      treeItem.on('loaded', (data) => {
        this.bindFEAAsset(treeItem)
      })
      return true
    }
    return false
  }

  /**
   * The itemRemovedFromScene method is called on each pass when aa item
   * is removed to the scene, and the pass must handle cleaning up any resources.
   * @param {TreeItem} treeItem - The treeItem value.
   * @param {object} rargs - Extra return values are passed back in this object.
   * @return {Boolean} - The return value.
   */
  itemRemovedFromScene(treeItem, rargs) {
    if (treeItem instanceof FEAAsset) {
      this.unbindFEAAsset(treeItem)
      return true
    }
    return false
  }

  /**
   * The bindFEAAsset method.
   * @param {FEAAsset} feaAsset - The feaAsset value.
   */
  bindFEAAsset(feaAsset) {
    const simUpdated = () => {
      this.indexArrayUpdateNeeded = true
    }
    feaAsset.on('simUpdated', simUpdated)

    const visibilityChanged = () => {}
    feaAsset.on('visibilityChanged', visibilityChanged)
    const cutAwayChanged = () => {
      feaAssetData.cutEnabled = feaAsset.isCutawayEnabled()
      if (feaAssetData.cutEnabled) {
        feaAssetData.cutVector = feaAsset.getCutVector().asArray()
        feaAssetData.cutDist = feaAsset.getCutDist()
      }

      this.emit('updated')
    }
    feaAsset.on('cutAwayChanged', cutAwayChanged)

    const globalXfoChanged = () => {
      feaAssetData.modelMatrix = feaAsset.getParameter('GlobalXfo').getValue().toMat4().asArray()
      this.emit('updated')
    }
    feaAsset.getParameter('GlobalXfo').on('valueChanged', globalXfoChanged)

    const activeNodeAttributeChanged = (event) => {
      if (!feaAssetData.nodeAttributes[event.name]) {
        const data = event.data
        const size = Math.sqrt(data.length)
        feaAssetData.nodeAttributes[event.name] = new GLTexture2D(gl, {
          format: 'RED',
          type: 'FLOAT',
          width: size,
          height: size,
          filter: 'NEAREST',
          wrap: 'CLAMP_TO_EDGE',
          mipMapped: false,
          data: data,
        })
      }
      this.nodeValuesTexture = feaAssetData.nodeAttributes[event.name]
      this.gradientRange = event.range
      this.valuesType = event.type
      this.emit('updated')
    }
    feaAsset.on('activeNodeAttributeChanged', activeNodeAttributeChanged)

    const gl = this.__renderer.gl
    const feaAssetData = {
      feaAsset,
      visibilityChanged,
      simUpdated,
      activeNodeAttributeChanged,
      elementNodeCounts: feaAsset.data.elementNodeCounts,
      numElements: feaAsset.data.numElements,
      nodeAttributes: {},
      cutEnabled: false,
    }

    cutAwayChanged()
    globalXfoChanged()

    // this.colorMaps['TEMP'] = new GLTexture2D(gl, feaAsset.gradient)

    this.feaAssets[0] = feaAssetData

    this.__updateRequested = true
    this.emit('updated')
  }

  /**
   * The unbindFEAAsset method.
   * @param {FEAAsset} feaAsset - The feaAsset value.
   */
  unbindFEAAsset(feaAsset) {
    const feaAssetData = this.feaAssets[0]

    feaAsset.off('simUpdated', feaAssetData.simUpdated)
    feaAsset.off('visibilityChanged', feaAssetData.visibilityChanged)

    this.feaAssets = []
    this.__updateRequested = true
    this.emit('updated')
  }

  /**
   * The uploadSim method.
   * @private
   */
  uploadSim() {
    const gl = this.__renderer.gl

    const feaData = this.feaAssets[0].feaAsset.data
    {
      const size = Math.sqrt(feaData.elementNodeIndices.length)

      this.elementNodeIndicesTexture = new GLTexture2D(gl, {
        format: 'RED',
        type: 'FLOAT',
        width: size,
        height: size,
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data: feaData.elementNodeIndices,
      })
    }
    {
      const size = Math.sqrt(feaData.nodePositions.length / 3)
      this.nodePositionsTexture = new GLTexture2D(gl, {
        format: 'RGB',
        type: 'FLOAT',
        width: size,
        height: size,
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data: feaData.nodePositions,
      })
    }

    // {
    //   const size = (Math.sqrt(feaData.feaValues.length))
    //   this.nodeValuesTexture = new GLTexture2D(gl, {
    //     format: 'RED',
    //     type: 'FLOAT',
    //     width: size,
    //     height: size,
    //     filter: 'NEAREST',
    //     wrap: 'CLAMP_TO_EDGE',
    //     mipMapped: false,
    //     data: feaData.feaValues
    //   })
    // }

    this.__updateRequested = false
  }

  /**
   * The sort method.
   * @param {object} renderstate - The object tracking the current state of the renderer
   */
  draw(renderstate) {
    if (this.feaAssets.length == 0) {
      return
    }
    if (this.__updateRequested) {
      this.uploadSim()
    }

    const gl = this.__gl
    gl.disable(gl.CULL_FACE)
    gl.enable(gl.DEPTH_TEST)
    gl.depthFunc(gl.LEQUAL)
    gl.depthMask(true)

    this.glshader.bind(renderstate, 'DRAW_COLOR')
    this.__draw(renderstate, this.enableTransparency)

    gl.disable(gl.BLEND)
  }

  __draw(renderstate, blend = false) {
    const gl = this.__gl
    const feaAssetData = this.feaAssets[0]

    const { cutPlaneNormal, cutPlaneDist, modelMatrix } = renderstate.unifs
    if (cutPlaneNormal && feaAssetData.cutEnabled) {
      gl.uniform3fv(cutPlaneNormal.location, feaAssetData.cutVector)
      gl.uniform1f(cutPlaneDist.location, feaAssetData.cutDist)
    }
    gl.uniformMatrix4fv(modelMatrix.location, false, feaAssetData.modelMatrix)

    let elementCount = 0
    feaAssetData.elementNodeCounts.forEach((count, index) => {
      if (!this.renderVolumetrics && index > 1) {
        //   // Skip beams for now.
        //   // Only a single beam in the intial sample set.
        //   elementCount += count * (index + 2)
        return
      }

      if (blend && index == 2) {
        // gl.disable(gl.DEPTH_TEST)
        // gl.disable(gl.CULL_FACE)
        gl.depthMask(false) // Disable dpeth writing.

        gl.enable(gl.BLEND)
        gl.blendEquation(gl.FUNC_ADD)
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA)
      }

      this.elementGeoms[index].bind(renderstate)

      const unifs = renderstate.unifs

      this.elementNodeIndicesTexture.bindToUniform(renderstate, unifs.elementNodeIndicesTexture)
      this.nodePositionsTexture.bindToUniform(renderstate, unifs.nodePositionsTexture)

      const { elementIndexOffset, elementNodeCount, bands } = renderstate.unifs
      gl.uniform1i(elementNodeCount.location, index + 2)
      gl.uniform1i(elementIndexOffset.location, elementCount)

      if (this.nodeValuesTexture && this.displaySolution) {
        const {
          nodeValuesTexture,
          nodeValuesTextureConnected,
          nodeValueColorGradient,
          gradientRange,
        } = renderstate.unifs
        this.nodeValuesTexture.bindToUniform(renderstate, nodeValuesTexture)

        gl.uniform3fv(gradientRange.location, this.gradientRange)
        gl.uniform1i(bands.location, this.bands)

        if (nodeValueColorGradient) {
          this.colorMaps[this.valuesType].bindToUniform(renderstate, nodeValueColorGradient)
          gl.uniform1i(nodeValuesTextureConnected.location, 1)
        }
      } else {
        const { nodeValuesTextureConnected } = renderstate.unifs
        if (nodeValuesTextureConnected) gl.uniform1i(nodeValuesTextureConnected.location, 0)
      }

      renderstate.bindViewports(unifs, () => {
        this.elementGeoms[index].drawInstanced(renderstate, count)
      })

      elementCount += count * (index + 2)
    })
  }

  /**
   * The drawHighlightedGeoms method.
   * @param {any} renderstate - The renderstate value.
   */
  drawHighlightedGeoms(renderstate) {
    if (this.feaAssets.length == 0) {
      return
    }
    if (this.__updateRequested) {
      this.uploadSim()
    }

    const gl = this.__gl

    gl.enable(gl.CULL_FACE)
    gl.cullFace(gl.BACK)
    gl.enable(gl.DEPTH_TEST)
    gl.depthFunc(gl.LESS)
    gl.depthMask(true)

    this.glshader.bind(renderstate, 'DRAW_HIGHLIGHT')
    this.__draw(renderstate)
  }

  /**
   * The drawGeomData method.
   * @param {any} renderstate - The renderstate value.
   */
  drawGeomData(renderstate) {
    if (this.feaAssets.length == 0) {
      return
    }
    if (this.__updateRequested) {
      this.uploadSim()
    }
    const gl = this.__gl

    gl.enable(gl.CULL_FACE)
    gl.cullFace(gl.BACK)
    gl.enable(gl.DEPTH_TEST)
    gl.depthFunc(gl.LESS)
    gl.depthMask(true)

    this.glshader.bind(renderstate, 'DRAW_GEOMDATA')

    gl.uniform1i(renderstate.unifs.passId.location, this.__passIndex)
    gl.uniform1i(renderstate.unifs.itemId.location, 0)

    this.__draw(renderstate)
  }

  /**
   * The getGeomItemAndDist method.
   * @param {any} geomData - The geomData value.
   * @return {any} - The return value.
   */
  getGeomItemAndDist(geomData) {
    const itemId = Math.round(geomData[1])
    const dist = geomData[3]

    const feaAssetData = this.feaAssets[itemId]
    if (feaAssetData) {
      return {
        geomItem: feaAssetData.feaAsset,
        dist,
        feaValue: geomData[2],
      }
    }
  }
}

export { GLFEAPass }
