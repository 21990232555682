﻿import { ScreenQuadShader, shaderLibrary } from '@zeainc/zea-engine'

// Writes out positions and normals for the current frame of the cloth sim,

class AccumDeltasShader extends ScreenQuadShader {
  constructor(gl) {
    super(gl)

    this.setShaderStage(
        'FRAGMENT_SHADER',
      `
precision highp float;

uniform int forwardsOrBackwards;
// uniform float maxDistMoved;

uniform sampler2D clusterAssignmentTexture;
uniform sampler2D clusterCentersTexture;
uniform sampler2D prevPoseTexture;

<%include file="GLSLUtils.glsl"/>

varying vec2 v_texCoord;


// // The data is compressed using a sigmoid function to 
// // bias precision bits to be used on the vertices moving 
// // at lower velocities. The atan function is the sigmoid.
// const float atanCurveRangeX = 6.0;
// const float atanCurveRangeY = atan(atanCurveRangeX);

// float decompressValue(float value, float maxDistMoved) {
//     int ival = int(value * 255.0);
//     float result = float(ival / 2) / 127.0;
//     result = tan(result*atanCurveRangeY) / atanCurveRangeX;
//     result *= maxDistMoved;
//     if(mod(float(ival), 2.0) == 0.0){
//         return result;
//     }
//     else{
//         return -result;
//     }
// }

vec3 getFrameDelta() {

    // vec3 delta = texture2D(deltaTexture, v_texCoord).rgb;
    // delta.x = decompressValue(delta.x, maxDistMoved);
    // delta.y = decompressValue(delta.y, maxDistMoved);
    // delta.z = decompressValue(delta.z, maxDistMoved);
    // return delta;
    
    int custerId = int((texture2D(clusterAssignmentTexture, v_texCoord).r * 255.0) + 0.5);

    int numClusters = 256;
    int clusterTextureWidth = 16;
    int x = custerId % clusterTextureWidth;
    int y = custerId / clusterTextureWidth;
    
    return texelFetch(clusterCentersTexture, ivec2(x, y), 0).rgb;
}


#ifdef ENABLE_ES3
    out vec4 fragColor;
#endif
void main(void) {
#ifndef ENABLE_ES3
    vec4 fragColor;
#endif

    vec3 pose = texture2D(prevPoseTexture, v_texCoord).rgb;
    if (forwardsOrBackwards == 1) {
        pose += getFrameDelta(); 
    } else {
        pose -= getFrameDelta();
    }
    
    fragColor.rgb = pose;

#ifndef ENABLE_ES3
    gl_FragColor = fragColor;
#endif
}

`
    )
  }
}

export { AccumDeltasShader }
