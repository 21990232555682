import { Lines } from '@zeainc/zea-engine'

/**
 * A class for generating a Beam geometry.
 *
 * @extends {Mesh}
 */
class Beam extends Lines {
  /**
   * Create a Beam.
   */
  constructor() {
    super()

    
    this.setNumSegments(1)
    this.setSegmentVertexIndices(0, 0, 1)
    this.setNumVertices(2)
  }
}

export { Beam }
