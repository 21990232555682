import { Mesh } from '@zeainc/zea-engine'

/**
 * A class for generating a Tetrahedron geometry.
 *
 * @extends {Mesh}
 */
class Tetrahedron extends Mesh {
  /**
   * Create a Tetrahedron.
   */
  constructor() {
    super()

    this.setFaceCounts([6])
    this.setFaceVertexIndices(0, [0, 2, 1])
    this.setFaceVertexIndices(1, [0, 1, 3])
    this.setFaceVertexIndices(2, [1, 2, 3])
    this.setFaceVertexIndices(3, [2, 0, 3])

    
    this.setFaceVertexIndices(4, [0+4, 1+4, 2+4])
    this.setFaceVertexIndices(5, [0+4, 2+4, 3+4])
    this.setNumVertices(8)
  }
}

export { Tetrahedron }
