import { Color, GLShader, shaderLibrary, Registry } from '@zeainc/zea-engine'

// import './GLSL/stack-gl/inverse.js'
// import './GLSL/stack-gl/transpose.js'

class SimStreamPointsShader extends GLShader {
  constructor(gl) {
    super(gl)
    this.setShaderStage(
        'VERTEX_SHADER',
      `
precision highp float;

// attribute vec3 positions;

uniform mat4 viewMatrix;
uniform mat4 projectionMatrix;
uniform float glPointSize;
// uniform float Overlay;

uniform mat4 modelMatrix;

// <%include file="stack-gl/transpose.glsl"/>
// <%include file="stack-gl/inverse.glsl"/>
// <%include file="drawItemId.glsl"/>
// <%include file="drawItemTexture.glsl"/>


uniform sampler2D framePoseTextureA;
uniform sampler2D framePoseTextureB;

uniform sampler2D clusterAssignmentTexture;
uniform sampler2D vertexColorsTextureA;
uniform sampler2D vertexColorsTextureB;

vec3 getFrameColor(sampler2D vertexColorsTexture, ivec2 texCoord) {
  int custerId = int((texelFetch(clusterAssignmentTexture, texCoord, 0).g * 255.0) + 0.5);

  int numClusters = 256;
  int clusterTextureWidth = 16;
  int x = custerId % clusterTextureWidth;
  int y = custerId / clusterTextureWidth;
  
  return texelFetch(vertexColorsTexture, ivec2(x, y), 0).rgb;
}


uniform float frameLerp;
uniform int poseTexSize;
uniform int geomSampleOffset; // The offset in  the pos tex for this geom.



ivec2 vertexIndexToUV(int index, int textureWidth){
  return ivec2(index % textureWidth, index / textureWidth);
}

/* VS Outputs */
varying vec4 v_color;


void main(void) {
  // int drawItemId = getDrawItemId();

  
  int texelID     = geomSampleOffset + int(gl_VertexID);
  ivec2 texelUv   = vertexIndexToUV(texelID, poseTexSize);

  vec3 posA       = texelFetch(framePoseTextureA, texelUv, 0).rgb;
  vec3 posB       = texelFetch(framePoseTextureB, texelUv, 0).rgb;
  
  // Note: we lerp from frame A >--> B. (A == prev frame. B == nextFrame)
  vec3 position   = mix(posA, posB, frameLerp);

  
  vec3 colorA       = getFrameColor(vertexColorsTextureA, texelUv);
  vec3 colorB       = getFrameColor(vertexColorsTextureB, texelUv);
  
  // Note: we lerp from frame A >--> B. (A == prev frame. B == nextFrame)
  vec3 color   = mix(colorA, colorB, frameLerp);
  
  mat4 modelViewProjectionMatrix = projectionMatrix * viewMatrix * modelMatrix;
  gl_Position = modelViewProjectionMatrix * vec4(position, 1.);
  
  gl_PointSize = glPointSize;

  v_color.rgb = color;
  
  // if(Overlay > 0.0){
  //   gl_Position.z = mix(gl_Position.z, -1.0, Overlay);
  // }
}
`
    )

    this.setShaderStage(
      'FRAGMENT_SHADER',
    `
precision highp float;

uniform color BaseColor;

/* VS Outputs */
varying vec4 v_color;

#ifdef ENABLE_ES3
out vec4 fragColor;
#endif

void main(void) {

#ifndef ENABLE_ES3
  vec4 fragColor;
#endif

  fragColor = v_color;

#ifndef ENABLE_ES3
  gl_FragColor = fragColor;
#endif
}
`
    )
  }

  static getParamDeclarations() {
    const paramDescs = super.getParamDeclarations()
    paramDescs.push({
      name: 'BaseColor',
      defaultValue: new Color(1.0, 1.0, 0.1),
    })
    paramDescs.push({ name: 'glPointSize', defaultValue: 2.5 })
    // paramDescs.push({ name: 'Overlay', defaultValue: 0.0 })
    return paramDescs
  }
}

Registry.register('SimStreamPoints', SimStreamPointsShader)

export { SimStreamPointsShader }
