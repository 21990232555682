﻿import { NumberParameter, FilePathParameter, resourceLoader, Points, GeomItem, TreeItem, Material } from '@zeainc/zea-engine'

class SimStreamAsset extends TreeItem {
  constructor(name) {
    super(name)

    this.__fps = 30
    this.__numFrames = 0

    // this.__geomLibrary.setGenBufferOption('includeVertexNeighbors', true)

    // this.__materials.setMaterialTypeMapping({
    //   '*': 'ClothGeomShader',
    // })

    // this.once('loaded', () => {
    //   // console.log(this.__materials.getMaterialNames());
    //   //   const image = new FileImage2D('../ClothPrint.jpg', resourceLoader)
    //   //   image.wrap = 'REPEAT'
    //   //   this.__materials.modifyMaterials(['Mat'], {
    //   //     baseColor: image,
    //   //     roughness: 1.9,
    //   //     metallic: 0.0,
    //   //   })
    // })

    const simFileParam = this.addParameter(new FilePathParameter('SimFilePath'))
    simFileParam.on('valueChanged', () => {
      const url = simFileParam.getUrl()
      this.loadSimDesc(url)
    })
    const timeParam = this.addParameter(new NumberParameter('Time'))
    timeParam.on('valueChanged', () => {
      this.emit('timeChanged', { time: timeParam.getValue() })
    })

    this.__loadDebugging = false
  }

  getNumFrames() {
    return this.__numFrames
  }

  getFps() {
    return this.__fps
  }

  getPoseTexSize() {
    return this.__poseTexSize
  }

  // getNumKeyFramePoses() {
  //   return this.__keyFramesData.length
  // }
  // getKeyFramePose(index) {
  //   return this.__keyFramesData[index]
  // }
  // getNumDeltas() {
  //   return this.__simData.length
  // }
  // getDeltaFrame(index) {
  //   return this.__simData[index]
  // }

  // getNumDebugFrames() {
  //   return this.__debuggingData.length
  // }
  // getDebugFrame(index) {
  //   return this.__debuggingData[index]
  // }

  // getSimVertexCount() {
  //   return this.__simVertexCount
  // }

  // getGeomByteOffsets() {
  //   return this.__geomByteOffsets
  // }

  loadKeyFrame(frameNumber) {
    return new Promise((resolve, reject) => {
      const url = this.__folder + this.__simName + frameNumber + '.keypose'
      resourceLoader.loadArchive(url).then((entries) => {
        console.log('Loaded Key Frame Pose:' + frameNumber)
        const positionsDataArray = new Float32Array(entries['data.bin'].buffer)
        this.emit('simKeyFrameLoaded', { frameNumber, positionsDataArray })
        resolve()
      })
    })
  }

  loadSimData() {
    resourceLoader.addWork(this.__simName, this.__numFrames * 4) // first geom file (load + parse + extra)

    // Now load the sim files in sequence, parsing and loading the next..
    const loadSimDeltaFile = (frameNumber) => {
      const url = this.__folder + this.__simName + frameNumber + '.delta'
      resourceLoader.loadArchive(url).then((entries) => {
          // console.log('Loaded :' + url)

          // this.__simData[frameNumber] = { data, metaData }
          // Send it to the renderer
          this.emit('simFrameLoaded', { frameNumber, entries })
          if (frameNumber < this.__numFrames - 1) {
            // Note: frame 0 is a keyframe, and each frame after is a delta.
            loadSimDeltaFile(frameNumber + 1)
          }
        },
        false
      ) // <----
      // Note: Don't add load work as we already pre-added it at the begining
      // and after the Tree file was loaded...
    }
    loadSimDeltaFile(0) // Frame 0 is a keyframe.

    if (this.__loadDebugging) {
      resourceLoader.addWork(this.__simName, this.__numFrames * 4) // first geom file (load + parse + extra)
      // Now load the sim files in sequence, parsing and loading the next..
      const loadDebuggingDataFile = (frameNumber) => {
        const url = this.__folder + 'debugging' + frameNumber + '.debugging'
        resourceLoader.loadArchive(url).then((entries) => {
            console.log("Loaded :" + url);
            const debuggingData = new Float32Array(entries['debugging.bin'].buffer)
            this.__debuggingData[frameNumber] = debuggingData
            this.emit('debugDataLoaded', { frameNumber, debuggingData })
            if (frameNumber < this.__numFrames - 1) {
              loadDebuggingDataFile(frameNumber + 1)
            }
          },
          false
        ) // <----
        // Note: Don't add load work as we already pre-added it at the begining
        // and after the Tree file was loaded...
      }
      // Note: the debugging is one frame ahead.
      // this is because there is no debugging for frame 0;
      loadDebuggingDataFile(0)
    }
  }

  fromJSON(url, json) {
    const parts = url.split('/')
    parts.pop()
    this.__folder = parts.join('/') + '/'
    this.__simName = json.sim
    this.__numFrames = json.numFrames
    this.__fps = json.fps
    this.__poseTexSize = json.poseTexSize
    this.getParameter('Time').setRange([0, (json.numFrames / this.__fps) * 1000])
    this.__simVertexCount = 0
    json.geomDatas.forEach((geomData) => {
      this.__simVertexCount += geomData.vertexCount
    })

    const points = new Points()
    points.setNumVertices(this.__simVertexCount)

    const material = new Material('points', 'SimStreamPoints')
    
    const pointsGeomItem = new GeomItem('points', points, material);
    this.addChild(pointsGeomItem)

    this.emit('simDescLoaded', {
      numFrames: json.numFrames,
      simVertexCount: this.__simVertexCount,
      poseTexSize: json.poseTexSize,
      geomByteOffsets: json.geomByteOffsets,
    })

    this.loadKeyFrame(0).then(() => {
      this.loadSimData()
    })
  }

  loadSimDesc(url) {
    function checkStatus(response) {
      if (!response.ok) {
        throw new Error(`HTTP ${response.status} - ${response.statusText}`)
      }
      return response
    }
    fetch(url)
      .then((response) => checkStatus(response) && response.json())
      .then((json) => {
        this.fromJSON(url, json)
      })
  }
}

export { SimStreamAsset }
