﻿import {
  GLTexture2D,
  GLFbo,
  GLPass,
  generateShaderGeomBinding,
  MathFunctions,
  GeomItem,
  Mesh,
  MeshProxy,
  GLMesh,
  Points,
  PointsProxy,
  GLPoints,
  Lines,
  LinesProxy,
  GLLines,
  GLMaterial,
  GLGeomItem,
} from '@zeainc/zea-engine'
import { AccumDeltasShader } from './AccumDeltasShader.js'
import { SimStreamAsset } from './SimStreamAsset.js'
import { SimStreamPointsShader } from './SimStreamPointsShader.js'

import { freeMem } from './freeMemWorker.js'

class GLSimStreamPass extends GLPass {
  constructor(gl, collector) {
    super(gl, collector)
  }

  init(renderer, passIndex) {
    super.init(renderer, passIndex)
    const gl = this.__gl
    if (!gl.__quadVertexIdsBuffer) {
      gl.setupInstancedQuad()
    }

    this.keyFrameTextures = []
    this.clusterAssignmentTextures = []
    this.displacementTextures = []
    this.colorTextures = []
    this.deltaMetadataValues = []
    this.deltaMetadataTextures = []
    this.debugDataTextures = []
    this.loadedDeltas = 0
    this.currFrame = 0
    this.renderedFrame = -1
    this.lerp = 0
    this.fboID = 0
    this.ready = false

    this.accumDeltasShader = new AccumDeltasShader(gl)
    const accumDeltasShaderComp = this.accumDeltasShader.compileForTarget()
    this.accumDeltasShaderBinding = generateShaderGeomBinding(
      gl,
      accumDeltasShaderComp.attrs,
      gl.__quadattrbuffers,
      gl.__quadIndexBuffer
    )

    // this.computeNormalsShader = new ClothNormalsShader(gl)
    // const computeNormalsShaderComp = this.computeNormalsShader.compileForTarget()
    // this.computeNormalsShaderBinding = generateShaderGeomBinding(
    //   gl,
    //   computeNormalsShaderComp.attrs,
    //   gl.__quadattrbuffers,
    //   gl.__quadIndexBuffer
    // )

    // this.clothGeomShader = new ClothGeomShader(gl)
    this.simStreamPointsShader = new SimStreamPointsShader(gl)
    this.drawItems = []

    // this.debugVectorsShader = new DebugVectorsShader(gl)

    
    renderer.registerPass(this.itemAddedToScene.bind(this), this.itemRemovedFromScene.bind(this))
  }

  /**
   * The itemAddedToScene method is called on each pass when a new item
   * is added to the scene, and the renderer must decide how to render it.
   * It allows Passes to select geometries to handle the drawing of.
   * @param {TreeItem} treeItem - The treeItem value.
   * @param {object} rargs - Extra return values are passed back in this object.
   * The object contains a parameter 'continueInSubTree', which can be set to false,
   * so the subtree of this node will not be traversed after this node is handled.
   * @return {Boolean} - The return value.
   */
  itemAddedToScene(treeItem, rargs) {
    if (treeItem instanceof SimStreamAsset) {
      const simStreamAsset = treeItem
      this.loadSimStreamAsset(simStreamAsset)

      simStreamAsset.on('simDescLoaded', () => {
        simStreamAsset.traverse((subTreeItem) => {
          if (subTreeItem instanceof GeomItem) {
            // if (shaderClass instanceof PointsStreamShader) {
            const geomItem = subTreeItem
            const material = geomItem.getParameter('Material').getValue()
            const geometry = geomItem.getParameter('Geometry').getValue()
            const shaderName = material.getShaderName()
            console.log('shaderName:', shaderName)

            const gl = this.__gl
            const glMaterial = this.addMaterial(material)
            const glGeom = this.addGeom(geometry)
            const glGeomItem = new GLGeomItem(gl, geomItem, glGeom, this.drawItems.length)

            this.drawItems.push({
              glGeom,
              glMaterial,
              glGeomItem,
            })
          }
        })
      })
      rargs.continueInSubTree = false
      return true
    }
    return false
  }

  /**
   * The itemRemovedFromScene method is called on each pass when aa item
   * is removed to the scene, and the pass must handle cleaning up any resources.
   * @param {TreeItem} treeItem - The treeItem value.
   * @param {object} rargs - Extra return values are passed back in this object.
   * @return {Boolean} - The return value.
   */
  itemRemovedFromScene(treeItem, rargs) {
    return false
  }

  /**
   * The addMaterial method.
   * @param {any} material - The material value.
   * @return {any} - The return value.
   */
  addMaterial(material) {
    let glmaterial = material.getMetadata('glmaterial')
    if (glmaterial) {
      return glmaterial
    }
    const glshader = this.simStreamPointsShader //this.renderer.getOrCreateShader(material.getShaderName())
    glmaterial = new GLMaterial(this.__gl, material, glshader)
    glmaterial.on('updated', () => {
      this.renderer.requestRedraw()
    })
    material.setMetadata('glmaterial', glmaterial)

    return glmaterial
  }

  /**
   * The addGeom method.
   * @param {any} geom - The geom value.
   * @return {any} - The return value.
   */
  addGeom(geom) {
    let glgeom = geom.getMetadata('glgeom')
    if (glgeom) {
      glgeom.addRef(this)
      return glgeom
    }
    const gl = this.__gl
    if (geom instanceof Mesh || geom instanceof MeshProxy) {
      glgeom = new GLMesh(gl, geom)
    } else if (geom instanceof Lines || geom instanceof LinesProxy) {
      glgeom = new GLLines(gl, geom)
    } else if (geom instanceof Points || geom instanceof PointsProxy) {
      glgeom = new GLPoints(gl, geom)
    } else {
      throw new Error('Unsupported geom type:' + geom.constructor.name)
    }
    geom.setMetadata('glgeom', glgeom)
    glgeom.on('updated', () => {
      this.renderer.requestRedraw()
    })
    glgeom.addRef(this)
    return glgeom
  }

  loadSimStreamAsset(simStreamAsset) {
    const gl = this.__gl

    simStreamAsset.on('simDescLoaded', () => {
      // const simVertexCount = simStreamAsset.getSimVertexCount()
      this.poseTexSize = simStreamAsset.getPoseTexSize()
      this.numFrames = simStreamAsset.getNumFrames()

      //////////////////////////////
      // Topology buffer
      // Now collect all the geom proxies and build the topology texture.
      // Note: the various topology textures are merged into a single topology
      // texture.
      // const geomLibrary = simStreamAsset.getGeometryLibrary()
      // let totalNeiIndices = 0
      // let totalVertices = 0
      // for (const geom of geomLibrary.geoms) {
      //   const buffers = geom.genBuffers()
      //   if (buffers.vertexNeighbors) totalNeiIndices += buffers.vertexNeighbors.length
      //   totalVertices += buffers.numVertices
      // }
      // const topoTexSize = MathFunctions.nextPow2(Math.round(Math.sqrt(totalNeiIndices) + 0.5))
      // const topologyData = new Float32Array(topoTexSize * topoTexSize)
      // let vertexOffset = 0
      // let tocOffset = 0
      // let dataOffset = totalVertices * 2
      // for (const geom of geomLibrary.geoms) {
      //   const buffers = geom.genBuffers()

      //   if (buffers.vertexNeighbors) {
      //     for (let i = 0; i < buffers.numVertices; i++) {
      //       const start = buffers.vertexNeighbors[i * 2]
      //       const count_flags = buffers.vertexNeighbors[i * 2 + 1]
      //       const flags = count_flags >> 8
      //       const count = count_flags - (flags << 8)

      //       // The start of the texture is the toc. (offset and count)
      //       // And the rest is the data (neightbor indices.)
      //       // We shift the toc values forward by the number of parsed vertices (*2)
      //       // and we shift the data values forward by the delta betwen the total
      //       // and this geoms vertex count.
      //       topologyData[tocOffset + i * 2] = dataOffset
      //       topologyData[tocOffset + i * 2 + 1] = count_flags
      //       for (let j = 0; j < count; j++) {
      //         topologyData[dataOffset + j] = buffers.vertexNeighbors[start + j] + vertexOffset
      //       }
      //       dataOffset += count
      //     }
      //   }
      //   tocOffset += buffers.numVertices * 2
      //   vertexOffset += buffers.numVertices
      // }
      // this.topoTexSize = topoTexSize
      // this.topologyTex = new GLTexture2D(gl, {
      //   type: 'FLOAT',
      //   format: 'RED',
      //   width: topoTexSize,
      //   height: topoTexSize,
      //   filter: 'NEAREST',
      //   mipMapped: false,
      //   wrap: 'CLAMP_TO_EDGE',
      //   data: topologyData,
      // })

      ///////////////////////////////////
      // accumulation textures
      const bufferParams = {
        type: 'FLOAT',
        format: 'RGBA',
        width: this.poseTexSize,
        height: this.poseTexSize,
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data: new Float32Array(this.poseTexSize * this.poseTexSize * 4 /*RGBA*/),
      }

      this.fbos = [new GLFbo(gl, new GLTexture2D(gl, bufferParams)), new GLFbo(gl, new GLTexture2D(gl, bufferParams))]
      this.fboID = 0

      // this.normalsFbos = [
      //   new GLFbo(gl, new GLTexture2D(gl, bufferParams)),
      //   new GLFbo(gl, new GLTexture2D(gl, bufferParams)),
      // ]

      //////////////////////////////
      // Generate a vertex Ids buffer big enough to render the largest geom.
      // The same buffer can be used by the smaller geoms.
      // this.geomByteOffsets = simStreamAsset.getGeomByteOffsets()
      // let maxVerts = 0
      // // Note: array contains pars of [byteOffset, vertexCount]
      // for (let i = 1; i < this.geomByteOffsets.length; i += 2) {
      //   const numVerts = this.geomByteOffsets[i]
      //   maxVerts += Math.max(maxVerts, numVerts)
      // }
      // const vertexIDs = new Float32Array(simStreamAsset.getSimVertexCount())
      // for (let i = 0; i < maxVerts; i++) {
      //   vertexIDs[i] = i
      // }
      // const vertexIdsAttrBuffer = gl.createBuffer()
      // gl.bindBuffer(gl.ARRAY_BUFFER, vertexIdsAttrBuffer)
      // gl.bufferData(gl.ARRAY_BUFFER, vertexIDs, gl.STATIC_DRAW)
      // this.extraAttrs = {
      //   vertexIDs: {
      //     buffer: vertexIdsAttrBuffer,
      //     instanced: false,
      //     dataType: this.FLOAT,
      //     dimension: 1,
      //     count: vertexIDs.length,
      //   },
      // }

      // simStreamAsset.on('debugDataLoaded', this.loadDebugDataFrame.bind(this))
      simStreamAsset.on('timeChanged', this.onTimeChanged.bind(this))
      
    })

    simStreamAsset.on('simKeyFrameLoaded', (event) => {
      const data = event.positionsDataArray
      const sideLength = Math.sqrt(data.length / 3)

      this.keyFrameTextures[event.frameNumber] = new GLTexture2D(gl, {
        type: 'FLOAT',
        format: 'RGB',
        width: this.poseTexSize,
        height: this.poseTexSize,
        filter: 'NEAREST',
        wrap: 'CLAMP_TO_EDGE',
        mipMapped: false,
        data,
      })

      if (event.frameNumber == 0) {
        this.framePoseTextureA = this.keyFrameTextures[0]
        this.framePoseTextureB = this.keyFrameTextures[0]
        
        // this.ready = true
      }
    })
    simStreamAsset.on('simFrameLoaded', (event) => {
      this.loadSimDeltaFrame(event)
    })

    this.simStreamAsset = simStreamAsset
  }

  loadSimDeltaFrame(event) {
    const { frameNumber, entries } = event
    console.log("loadSimDeltaFrame:", frameNumber)

    const clusterAssignments = entries['clusterAssignments.bin']
    const deltaClusterCenters = entries['deltaClusterCenters.bin']
    const colorClusterCenters = entries['colorClusterCenters.bin']

    const gl = this.__gl

    this.clusterAssignmentTextures[frameNumber] = new GLTexture2D(gl, {
      type: 'UNSIGNED_BYTE',
      format: 'RG',
      width: this.poseTexSize,
      height: this.poseTexSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: clusterAssignments,
    })

    const custerTextureSize = Math.sqrt(256)

    this.displacementTextures[frameNumber]  = new GLTexture2D(gl, {
      type: 'FLOAT',
      format: 'RGB',
      width: custerTextureSize,
      height: custerTextureSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: new Float32Array(deltaClusterCenters.buffer),
    })

    this.colorTextures[frameNumber]  = new GLTexture2D(gl, {
      type: 'UNSIGNED_BYTE',
      format: 'RGBA',
      width: custerTextureSize,
      height: custerTextureSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: new Uint8ClampedArray(colorClusterCenters.buffer),
    })

    freeMem([clusterAssignments.buffer, deltaClusterCenters.buffer, colorClusterCenters.buffer])

    // const deltas = new Float32Array(deltaClusterCenters.buffer)
    // const numVertices = clusterAssignments.length / 2
    // for (let i=0; i < numVertices; i++) {
    //   const c = clusterAssignments[i*2]
    //   const delta = [deltas[c * 3], deltas[c * 3+1], deltas[c * 3+2]]
    //   console.log(i, "delta:",c, delta)
    // }

    // this.colorTextures[index] = new GLTexture2D(gl, {
    //   type: 'UNSIGNED_BYTE',
    //   format: 'RGB',
    //   width: this.poseTexSize,
    //   height: this.poseTexSize,
    //   filter: 'NEAREST',
    //   wrap: 'CLAMP_TO_EDGE',
    //   mipMapped: false,
    //   data: colorsDataArray,
    // })

    /*
    const deltasDataArray = entries['positions.bin']
    const colorsDataArray = entries['vertexColors.bin']
    let metaData = JSON.parse(new TextDecoder('utf-8').decode(entries['data.json']))

    // if (!this.deltasTexture) return
    const index = frameNumber - 1
    // console.log("loadSimDeltaFrame:" +  index);
    const gl = this.__gl

    this.clusterAssignmentTextures[index] = new GLTexture2D(gl, {
      type: 'UNSIGNED_BYTE',
      format: 'RGB',
      width: this.poseTexSize,
      height: this.poseTexSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: deltasDataArray,
    })
    this.deltaMetadataValues[index] = metaData.maxDistMoved

    this.colorTextures[index] = new GLTexture2D(gl, {
      type: 'UNSIGNED_BYTE',
      format: 'RGB',
      width: this.poseTexSize,
      height: this.poseTexSize,
      filter: 'NEAREST',
      wrap: 'CLAMP_TO_EDGE',
      mipMapped: false,
      data: colorsDataArray,
    })
    */

    if (!this.ready) {
      this.ready = true
      this.emit('updated')
    }
  }

  // __loadDebugDataFrame(frameNumber, data) {
  //   const gl = this.__gl

  //   const pixelsPerItem = 4 // The number of RGBA pixels per draw item.
  //   const size = Math.sqrt(data.length / 4)
  //   // Only support power 2 textures. Else we get strange corruption on some GPUs
  //   // in some scenes.
  //   // size = MathFunctions.nextPow2(size);
  //   this.debugDataTexturesSize = size
  //   this.debugDataVectorCount = Math.floor(data.length / 16)

  //   this.debugDataTextures[frameNumber] = new GLTexture2D(gl, {
  //     format: 'RGBA',
  //     type: 'FLOAT',
  //     width: size,
  //     height: size,
  //     filter: 'NEAREST',
  //     wrap: 'CLAMP_TO_EDGE',
  //     mipMapped: false,
  //     data,
  //   })

  //   if (!this.shaderBound) {
  //     const instanceIdsArray = new Float32Array(this.debugDataVectorCount)
  //     for (let i = 0; i < this.debugDataVectorCount; i++) {
  //       instanceIdsArray[i] = i
  //     }
  //     const instanceIdsBuffer = gl.createBuffer()
  //     gl.bindBuffer(gl.ARRAY_BUFFER, instanceIdsBuffer)
  //     gl.bufferData(gl.ARRAY_BUFFER, instanceIdsArray, gl.STATIC_DRAW)

  //     this.debugVecInstanceIdsBuffer = instanceIdsBuffer
  //     if (!gl.__linesegattrbuffers) {
  //       gl.setupLineSegAttrBuffers()
  //     }

  //     const shaderComp = this.debugVectorsShader.compileForTarget()
  //     this.debugVectorsShaderBinding = generateShaderGeomBinding(
  //       gl,
  //       shaderComp.attrs,
  //       {},
  //       null,
  //       gl.__linesegattrbuffers
  //     )

  //     this.shaderBound = true
  //   }
  // }

  onTimeChanged(event) {
    const { time } = event

    const fps = this.simStreamAsset.getFps()
    // Note: The current frame is at the end of the lerp.
    let frame =  MathFunctions.clamp((time / 1000) * fps, 0, this.displacementTextures.length-1)
    this.lerp = MathFunctions.fract(frame)
    frame = Math.floor(frame)
    // console.log("onTimeChanged:" + frame + " lerp:" + this.lerp + " prev:" + this.currFrame);
    this.currFrame = frame

    if (this.currFrame != this.renderedFrame) {
      this.renderFramePose()
    }
    this.emit('updated')
  }

  renderFramePose() {
    // console.log("renderFramePose:" + this.currFrame + " :" + this.renderedFrame);

    // if (this.currFrame <= 0) {
    //   this.framePoseTextureB = this.keyFrameTextures[0]
    // }

    const renderstate = {}
    this.accumDeltasShader.bind(renderstate)
    this.accumDeltasShaderBinding.bind(renderstate)

    const unifs = renderstate.unifs

    const gl = this.__gl

    const swapTargetFbo = () => {
      this.framePoseTextureA = this.framePoseTextureB
      this.fboID = (this.fboID + 1) % this.fbos.length
      this.framePoseTextureB = this.fbos[this.fboID].getColorTexture()
      return this.fbos[this.fboID]
    }

    gl.uniform2fv(unifs.pos.location, [0.0, 0.0])
    gl.uniform2fv(unifs.size.location, [1.0, 1.0])

    // Apply all the deltas since the last pose was computed.
    const boundTextures = renderstate.boundTextures
    if (this.renderedFrame < this.currFrame) {
      for (let i = this.renderedFrame+1; i <= this.currFrame; i++) {
        if (i + 1 >= this.displacementTextures.length) continue
        const fboTarg = swapTargetFbo()
        fboTarg.bindForWriting()

        this.framePoseTextureA.bindToUniform(renderstate, unifs.prevPoseTexture)
        this.clusterAssignmentTextures[i+1].bindToUniform(renderstate, unifs.clusterAssignmentTexture)
        this.displacementTextures[i+1].bindToUniform(renderstate, unifs.clusterCentersTexture)
        
        // gl.uniform1f(unifs.maxDistMoved.location, this.deltaMetadataValues[i])
        gl.uniform1i(unifs.forwardsOrBackwards.location, 1)
        gl.drawQuad()
        fboTarg.unbindForWriting()
        renderstate.boundTextures = boundTextures

        this.renderedFrame++
      }
    } else {
      for (let i = this.renderedFrame-1; i >= this.currFrame; i--) {
        if (i < 0) continue
        const fboTarg = this.fbos[this.fboID]
        fboTarg.bindForWriting()
        if (i == 0) {
          this.framePoseTextureA = this.keyFrameTextures[0]
          this.renderedFrame--
          continue
        } else {
          this.framePoseTextureA.bindToUniform(renderstate, unifs.prevPoseTexture)
        }
        this.clusterAssignmentTextures[i].bindToUniform(renderstate, unifs.clusterAssignmentTexture)
        this.displacementTextures[i].bindToUniform(renderstate, unifs.clusterCentersTexture)
        // gl.uniform1f(unifs.maxDistMoved.location, this.deltaMetadataValues[i])
        gl.uniform1i(unifs.forwardsOrBackwards.location, -1)
        gl.drawQuad()
        fboTarg.unbindForWriting()
        renderstate.boundTextures = boundTextures

        swapTargetFbo()
        this.renderedFrame--
      }
    }

    // Debug a block of pixels.
    // {
    //   gl.finish();
    //   const fboTarg = this.fbos[this.fboID]
    //   fboTarg.bindForReading()
    //   const numVertices = 6
    //   const pixels = new Float32Array(4*numVertices);
    //   gl.readPixels(4, 0, numVertices, 1, gl.RGBA, gl.FLOAT, pixels);
    //   console.log(pixels);
    //   fboTarg.unbindForReading()
    // }

    // this.renderNormals()
  }

  // renderNormals() {
  //   this.frameNormalsTextureA = this.frameNormalsTextureB

  //   const fboTarg = this.normalsFbos[this.fboID]
  //   fboTarg.bind()

  //   const renderstate = {}
  //   this.computeNormalsShader.bind(renderstate)
  //   this.computeNormalsShaderBinding.bind(renderstate)

  //   let unifs = renderstate.unifs
  //   this.topologyTex.bindToUniform(renderstate, unifs.clothTopoTexture)
  //   this.framePoseTextureB.bindToUniform(renderstate, unifs.framePoseTexture)

  //   let gl = this.__gl
  //   gl.uniform1i(unifs.topoTexSize.location, this.topoTexSize)
  //   gl.uniform1i(unifs.poseTexSize.location, this.poseTexSize)

  //   gl.uniform2fv(unifs.pos.location, [0.0, 0.0])
  //   gl.uniform2fv(unifs.size.location, [1.0, 1.0])
  //   gl.drawQuad()

  //   // // Debug a block of pixels.
  //   // gl.finish();
  //   // let pixels = new Float32Array(this.poseTexSize*4*2);
  //   // gl.readPixels(0, 0, this.poseTexSize, 2, gl.RGBA, gl.FLOAT, pixels);
  //   // console.log(pixels);

  //   fboTarg.unbind()

  //   this.frameNormalsTextureB = fboTarg.getColorTexture()
  // }

  draw(renderstate) {
    if (!this.simStreamAsset || !this.ready) return

    const gl = this.__gl

    ////////////////////////////////////////////////////
    // Draw Cloth Geoms
    // console.log('renderPose:' + this.currFrame + ' :' + this.lerp)
    // this.bindShader(renderstate, this.clothGeomShader)
    // this.clothGeomShader.bind(renderstate)
    this.simStreamPointsShader.bind(renderstate)
    let unifs = renderstate.unifs

    // Note: we lerp from frame A >--> B. (A == prev frame. B == nextFrame)
    this.clusterAssignmentTextures[this.currFrame].bindToUniform(renderstate, unifs.clusterAssignmentTexture)
    this.framePoseTextureA.bindToUniform(renderstate, unifs.framePoseTextureA)
    this.colorTextures[this.currFrame].bindToUniform(renderstate, unifs.vertexColorsTextureA)

    if (this.lerp > 0) {
      this.framePoseTextureB.bindToUniform(renderstate, unifs.framePoseTextureB)
      this.colorTextures[Math.min(this.currFrame + 1, this.colorTextures.length-1)].bindToUniform(
        renderstate,
        unifs.vertexColorsTextureB
      )
    }
    // this.frameNormalsTextureB.bindToUniform(renderstate, unifs.frameNormalsTextureB)
    // this.frameNormalsTextureA.bindToUniform(renderstate, unifs.frameNormalsTextureA)

    gl.uniform1f(unifs.frameLerp.location, this.lerp)
    gl.uniform1i(unifs.poseTexSize.location, this.poseTexSize)

    // gl.enable(gl.BLEND)
    // gl.blendEquation(gl.FUNC_ADD)
    // gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA) // For add

    let geomSampleOffset = 0
    let geomId = 1

    this.drawItems.forEach((drawItem) => {
      drawItem.glMaterial.bind(renderstate)
      drawItem.glGeom.bind(renderstate)
      const i = 0
      gl.uniform1i(unifs.geomSampleOffset.location, geomSampleOffset)

      // gl.uniform1i(unifs.instancedDraw.location, 0)
      // gl.disableVertexAttribArray(renderstate.attrs.instancedIds.location)

      drawItem.glGeomItem.bind(renderstate)

      drawItem.glGeom.draw(renderstate)

      // geomSampleOffset += this.geomByteOffsets[geomId]
      geomId += 2
    })

    this.simStreamPointsShader.unbind(renderstate)

    // gl.disable(gl.BLEND)
    ////////////////////////////////////////////////////
    // Draw debug vectors.
    // if (this.debugVectorsShaderBinding) {
    //   // console.log("renderDebugging:" + this.currFrame + " :" + this.lerp);

    //   // this.bindShader(renderstate, this.debugVectorsShader)
    //   this.debugVectorsShader.bind(renderstate)
    //   this.debugVectorsShaderBinding.bind(renderstate)
    //   unifs = renderstate.unifs

    //   const frame = Math.min(this.currFrame, this.debugDataTextures.length - 1)
    //   if (frame == 0) {
    //     this.debugDataTextures[frame].bindToUniform(renderstate, unifs.debugDataTextureA)
    //   } else {
    //     this.debugDataTextures[frame - 1].bindToUniform(renderstate, unifs.debugDataTextureA)
    //   }
    //   this.debugDataTextures[frame].bindToUniform(renderstate, unifs.debugDataTextureB)
    //   gl.uniform1i(unifs.debugDataTextureSize.location, this.debugDataTexturesSize)
    //   gl.uniform1f(unifs.frameLerp.location, this.lerp)

    //   {
    //     // The instance transform ids are bound as an instanced attribute.
    //     const location = renderstate.attrs.instanceIds.location
    //     gl.bindBuffer(gl.ARRAY_BUFFER, this.debugVecInstanceIdsBuffer)
    //     gl.enableVertexAttribArray(location)
    //     gl.vertexAttribPointer(location, 1, gl.FLOAT, false, 4, 0)
    //     gl.__ext_Inst.vertexAttribDivisorANGLE(location, 1) // This makes it instanced
    //   }

    //   gl.__ext_Inst.drawArraysInstancedANGLE(gl.LINES, 0, 2, this.debugDataVectorCount)
    // }
  }
}
export { GLSimStreamPass }
